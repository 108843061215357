import "./App.css";

import geo from "./img/icons/geo.svg";
import check from "./img/icons/check.svg";
import folder from "./img/icons/folder.svg";
import bubble from "./img/icons/bubble.svg";
import contact from "./img/icons/contact.svg";

export function App() {
  return (
    <main className="px-10 md:px-0 py-10 md:py-40 container max-w-2xl mx-auto">
      <section>
        <div>
          <h1 className="heading text-4xl">Arno van Doesburg</h1>
        </div>
        <div className="mt-2">
          <p>
            Freelance Front-end Developer & Game Developer
            <br />
            <br />
            As a naturally curious developer, I'm always eager to learn new
            technologies and approach technical challenges with a positive
            attitude, finding the most fitting solutions to bring projects to
            life.
          </p>
        </div>
        <div className="mt-8 jus flex flex-col md:flex-row gap-2 md:gap-20">
          <IconWithText icon={geo}>Utrecht,NL</IconWithText>
          <IconWithText icon={check}>Available for work</IconWithText>
        </div>
        <div className="mt-8">
          <Navigation />
        </div>
      </section>
    </main>
  );
}

function IconWithText({ children, icon, animate = false }) {
  return (
    <div
      className={`flex items-center gap-2 ${
        animate && "md:hover:gap-3 transition-all"
      }`}
    >
      <img alt="icon" className="h-5 w-5 inline-block" src={icon} />
      <span className="leading-4">{children}</span>
    </div>
  );
}

function Navigation() {
  const items = [
    {
      title: "Github",
      icon: folder,
      href: "https://github.com/ArnovanDoesburg",
    },
    {
      title: "LinkedIn",
      icon: bubble,
      href: "https://www.linkedin.com/in/arno-van-doesburg-0264526b/",
    },
    // {
    //   title: "Steam",
    //   icon: link,
    //   href: "https://store.steampowered.com/search/?developer=Retrobright",
    // },
    // {
    //   title: "Livestream",
    //   icon: stream,
    //   href: "https://www.twitch.tv/jakeblade",
    // },
    {
      title: "Contact",
      icon: contact,
      href: "mailto:arnovandoesburg@gmail.com",
    },
  ];

  return (
    <nav>
      <ol className="inline-block underline">
        {items.map(({ title, icon, href }) => (
          <li className="py-1">
            <a className="my-2" {...{ href }}>
              <IconWithText {...{ icon }} animate>
                <span>{title}</span>
              </IconWithText>
            </a>
          </li>
        ))}
      </ol>
    </nav>
  );
}
